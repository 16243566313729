import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import Card from "../../cards/Card";

import {Header2, Title} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {ButtonContainer, ButtonLink} from "../../styled/button/ButtonComponents";

const Solutions = ({title, subTitle, challangesCards, subTitle2, solutionCards, button, buttonStyle}) => {
    return (
        <SectionLayoutGrid id={"solution"} customBackground="middle">
            {title &&
            <Col>
                <Title color="white" toolong="true">
                    {title}
                </Title>
            </Col>
            }
            {subTitle &&
            <Col>
                <Header2 color="white">{subTitle}</Header2>
            </Col>
            }
            {challangesCards && challangesCards.map((item, index) => {
                if (challangesCards.length % 2 === 1 && index === challangesCards.length - 1) {
                    return (
                        <React.Fragment key={index}>
                            <Col span={3}/>
                            <Col span={6} grid>
                                <Card title={item.title} description={item.description}
                                      avatar={item.avatar.url}/>
                            </Col>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <Col span={6} grid key={index}>
                            <Card
                                title={item.title}
                                description={item.description}
                                avatar={item.avatar.url}
                            />
                        </Col>
                    )
                }
            })}
            {subTitle2 &&
            <Col marginTop="medium">
                <Header2 color="white">{subTitle2}</Header2>
            </Col>
            }
            {solutionCards && solutionCards.map((item, index) => ((solutionCards.length % 2 === 1 && index === solutionCards.length - 1)
                    ?
                    (
                        <React.Fragment key={index}>
                            <Col span={3}/>
                            <Col span={6} grid>
                                <Card title={item.title} description={item.description}
                                      avatar={item.avatar.url}/>
                            </Col>
                        </React.Fragment>
                    )
                    :
                    (
                        <Col span={6} grid key={index}>
                            <Card
                                title={item.title}
                                description={item.description}
                                avatar={item.avatar.url}
                            />
                        </Col>
                    )
            ))}
            {button && button.length === 1 &&
            <Col grid center>
                <ButtonContainer>
                    <ButtonLink to={button[0].link} color={buttonStyle}>
                        {button[0].name}
                    </ButtonLink>
                </ButtonContainer>
            </Col>
            }
            {button && button.length === 2 &&
            <Col grid center>
                <ButtonContainer toolong="true">
                    <ButtonLink to={button[0].link} color={buttonStyle} toolong="true">
                        {button[0].name}
                    </ButtonLink>
                    <ButtonLink to={button[1].link} color="secondary-brand" toolong="true">
                        {button[1].name}
                    </ButtonLink>
                </ButtonContainer>
            </Col>
            }
        </SectionLayoutGrid>
    )
}

export default Solutions
