import React from "react";

import BenefitsCard from "../../cards/BenefitsCard"
import SectionLayoutGrid from "../../layouts/SectionLayoutGrid"

import {Header2} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {ButtonA, ButtonLink, ButtonContainer} from "../../styled/button/ButtonComponents";

const Benefits = ({
                      big_title,
                      small_title,
                      benefits,
                      button,
                      button_download,
                      button_style,
                      noID,
                      isExternal,
                      customPadding
                  }) => {
    return (
        <SectionLayoutGrid customBackground="middle" id={!noID ? "Benefits" : ""} customPadding={customPadding} customGap>
            <Col>
                <Header2 color="white" marginBottom="small">{big_title ? big_title : small_title}</Header2>
            </Col>
            {benefits.map((item) => {
                return (
                    <BenefitsCard key={item.id} title={item.title} description={item.description}
                                  avatar={item.avatar ? item.avatar.url : null} />
                )
            })}
            {(button || button_download) && !isExternal &&
            <Col center grid>
                <ButtonContainer>
                    {button && <ButtonLink to={button.link} color={button_style}>{button.name}</ButtonLink>}
                    {button_download &&
                    <ButtonA href={button_download.file.url} target="_blank" rel="noreferrer"
                             color="secondary-brand">{button_download.name}</ButtonA>}
                </ButtonContainer>
            </Col>
            }
            {button && isExternal &&
            <Col center grid>
                <ButtonContainer>
                    {button && <ButtonA href={button[0].link} target="_blank" rel="noreferrer" color={button_style}>{button[0].name}</ButtonA>}
                </ButtonContainer>
            </Col>
            }
        </SectionLayoutGrid>
    )
}

export default Benefits
