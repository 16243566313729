import React from "react";

import {
    BenefitsCardContainer,
    BenefitsCardWrapper, OneLineAvatar,
    OneLineBenefitsCardContainer,
    OneLineBenefitsCardWrapper, OneLineBody,
} from "../styled/cards/BenefitsCardComponents";
import {Body, Header3} from "../styled/typography/Typography";

const BenefitsCard = ({title, description, avatar}) => {
        return (
            <>
                {title && description && !avatar &&
                <BenefitsCardWrapper>
                    <BenefitsCardContainer>
                        <Header3 color="primary-brand" marginBottom="small">{title}</Header3>
                        <Body color="body-text">{description}</Body>
                    </BenefitsCardContainer>
                </BenefitsCardWrapper>
                }
                {title && !avatar && !description &&
                <OneLineBenefitsCardWrapper>
                    <OneLineBenefitsCardContainer noavatar>
                        <OneLineBody small>{title}</OneLineBody>
                    </OneLineBenefitsCardContainer>
                </OneLineBenefitsCardWrapper>
                }
                {title && avatar && !description &&
                <OneLineBenefitsCardWrapper>
                    <OneLineBenefitsCardContainer>
                        <OneLineAvatar src={avatar} alt={title}/>
                        <OneLineBody small>{title}</OneLineBody>
                    </OneLineBenefitsCardContainer>
                </OneLineBenefitsCardWrapper>
                }
            </>
        );
    }
;

export default BenefitsCard;
