import styled from "styled-components";

export const CardWrapper = styled.div`
  display: grid;
  align-self: stretch;
  align-items: start;
  padding: 2rem;
  grid-row-gap: 0.5rem;
  background-color: #173f6c;
  box-shadow: 1rem 1rem 3rem rgba(0, 0, 0, 0.1);
  transition: box-shadow 500ms;
  grid-template-rows: ${props => props.noavatar ? "4rem auto" : "8rem 4rem auto"};

  border-bottom: solid 0.25rem #00aeef;

  &:hover {
    box-shadow: 0 0 1rem 0 #4fa0e2;
  }
`

export const CardImg = styled.img`
  justify-self: center;
  height: 8rem;
  width: auto;
  max-width: 60%;
`