import styled from "styled-components";
import {Body} from "../typography/Typography";

export const BenefitsCardWrapper = styled.div`
  align-self: stretch;
  background: transparent;
  border-left: 5px solid #00aeef;
  border-radius: 0;
  padding: 20px 0px 20px 0;
  grid-column: 1/-1 !important;
`

export const BenefitsCardContainer = styled.div`
  background: var(--secondary-brand);
  padding: 40px;
  @media screen and (max-width: 800px) {
    padding: 20px;
    margin: 10px 0 10px 0;
  }
`

export const OneLineBenefitsCardWrapper = styled.div`
  display: grid;
  align-self: stretch;
  background: transparent;
  border-left: 0.25rem solid #00aeef;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0;
  margin-bottom: 0.5rem;
  grid-column: span 6 !important;
  @media screen and (max-width: 800px) {
    grid-column: 1/-1 !important;
  }
`

export const OneLineBenefitsCardContainer = styled.div`
  background: var(--secondary-brand);
  display: grid;
  grid-template-columns: ${props => props.noavatar ? "auto" : "50px auto"};
  align-items: center;
  padding: ${props => props.noavatar ? "0.5rem 0.5rem 0.5rem 1rem" : "0.5rem 0.5rem 0.5rem 0"};
  min-height: 2.5rem;
  color: var(--body-text);
  transition: 0.2s color;

  &:hover{
    color: var(--white);
  }
`

export const OneLineAvatar = styled.img`
  height: 30px;
  width: 100%;
  justify-self: center;
`

export const OneLineBody = styled(Body)`
  font-weight: 550 !important;
`