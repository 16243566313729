import React from "react";

import {Body, BodyLi, Header3} from "../styled/typography/Typography";
import {CardImg, CardWrapper} from "../styled/cards/CardComponents";

const Card = ({title, avatar, description, list}) => {

    return (
        <CardWrapper noavatar={avatar ? "" : "true"}>
            {avatar && <CardImg src={avatar} alt={title}/>}
            {title && <Header3 textAlign="center" color="white">{title}</Header3>}
            {description && <Body color="body-text">{description}</Body>}
            {list &&
            <ul>
                {list.map((item) => {
                    return (
                        <BodyLi key={item.id} color="body-text">{item.item}</BodyLi>
                    )
                })}
            </ul>
            }
        </CardWrapper>
    )
}

export default Card;